<template>
  <v-app-bar class="pa-0">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="onDrawerIconClick">
      </v-app-bar-nav-icon>
    </template>


    <v-toolbar-title>{{ siteName }}</v-toolbar-title>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer_visible">
    <template v-slot:prepend>
      <div class="px-3 pt-4 mb-1">Привет, {{ user.name }}.</div>

      <div class="px-3 mb-3 text-body-2">
        <p><strong>Юр. лицо: </strong>{{ user.client.company }}</p>
        <p><strong>ИНН: </strong>{{ user.client.inn }}</p>
      </div>
    </template>

    <v-divider></v-divider>
    <v-list nav>
      <template v-for="(item, i) in navigation_items">
        <v-list-item :key="i" :value="item" color="primary" :to="item.path" :active="item.path === route.path"
          v-if="item?.permission">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.title">
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>


    <template v-slot:append>

      <div class="pa-2">
        <v-btn v-if="data.verified" prepend-icon="mdi-account" block to="/board/user/profile" class="mb-3">
          Профиль
        </v-btn>
        <v-btn prepend-icon="mdi-logout" color="primary" block @click="handleSignOut">
          Выйти
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { useDisplay } from 'vuetify';
const { siteName } = useRuntimeConfig();
const drawer_visible = ref(false);
const {
  data,
  signOut,
} = useAuth();

const route = useRoute();

const { lgAndUp } = useDisplay();
if (lgAndUp.value) drawer_visible.value = true;
const { userData, setUserData } = useUserData();
setUserData(data.value.user);

const user = ref(data.value.user);
watch(
  () => userData.value,
  () => {
    user.value = userData.value;
  },
  { immediate: false }
)

const handleSignOut = async () => {
  await signOut({ callbackUrl: '/' })
}

function onDrawerIconClick() {
  drawer_visible.value = !drawer_visible.value
}
let navigation_items = [
  {
    title: 'Главная',
    value: 'home',
    icon: "mdi-home-outline",
    path: "/board",
    permission: true
  },
  {
    title: 'Счета',
    value: 'invoices',
    icon: "mdi-receipt-text-check",
    path: "/board/invoices",
    permission: data.value.verified
  },
  {
    title: 'Дефицит',
    value: 'shortage',
    icon: "mdi-file-document-multiple-outline",
    path: "/board/shortage",
    permission: data.value.verified
  },
  {
    title: 'Рентабельность',
    value: 'profitability',
    icon: "mdi-cash",
    path: "/board/profitability",
    permission: data.value.verified
  }
];

</script>

<style lang="css" scoped>
:deep() .v-toolbar {
  padding: 0px !important;
}
</style>