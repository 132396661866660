<template>
  <v-app id="inspire">

    <SidebarMenu />
    <v-main>
      <v-container class="pt-5" fluid>
        <slot />
      </v-container>

    </v-main>

  </v-app>
</template>
<script setup>

</script>

<style lang="sass">
#__nuxt
  display: flex
  flex-direction: column
  min-height: 100vh
 

main 
  flex-grow: 1
 
</style>